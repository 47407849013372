<template>
  <div>
    <b-card>
      <ValidationObserver ref="horseGender" v-slot="{ handleSubmit }">
        <b-form class="form-top p-4" @submit.prevent="handleSubmit(formSubmit)">
        <b-row>
          <b-col md="6">
            <input-form v-model="horseGender.horse_sex.translations[0].name" :name="$t('horseGenders.nameAr')" :label="$t('horseGenders.nameAr')" validate="required" :disabled="disableStatus" />
          </b-col>
          <b-col md="6">
            <input-form v-model="horseGender.horse_sex.translations[1].name" :name="$t('horseGenders.nameEn')" :label="$t('horseGenders.nameEn')" validate="required" :disabled="disableStatus" />
          </b-col>
        </b-row>

        <form-btns v-if="currentPage != itemPages.view" :label="submitLabel" :loading="loading" />
      </b-form>
      </ValidationObserver>
    </b-card>

    <b-card v-if="currentPage == itemPages.view">
      <horses :horseSexId="id" :titleStatus="true" />
    </b-card>
  </div>
</template>
<script>
import { core } from '../../../../config/pluginInit'
import horseGendersServices from '../services/horseGenders'
import HorseGender from '../models/HorseGender'
import Horses from '../../horses/views/Horses.vue'
import moduleItemMixin from '../../../../Mixins/moduleItemMixin'

export default {
  mixins: [moduleItemMixin],
  components: { Horses },
  data () {
    return {
      horseGender: new HorseGender()
    }
  },
  methods: {
    async create () {
      horseGendersServices.create({
        translations: this.horseGender.horse_sex.translations
      }).then(response => {
        this.loading = false
        core.showSnackbar('success', response.data.message)
        this.lastRoute.name ? this.$router.push(this.lastRoute) : this.$router.push({ name: 'contributor.horseGenders' })
      }).catch(() => {
        this.loading = false
      })
    },
    async update () {
      horseGendersServices.update(this.id, {
        translations: this.horseGender.horse_sex.translations
      }).then(response => {
        this.loading = false
        core.showSnackbar('success', response.data.message)
        this.lastRoute.name ? this.$router.push(this.lastRoute) : this.$router.push({ name: 'contributor.horseGenders' })
      }).catch(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    core.index()
  },
  created () {
    if (this.id) {
      horseGendersServices.findOne(this.id).then(response => {
        this.horseGender.fillData(response.data.data)
        this.loading = false
      })
    } else {
      this.loading = false
    }
  }
}
</script>
